table{
	width: 100%;
    background-color: transparent;
    border-spacing: 0;

    td,th{
    	padding: 1rem;
    	text-align: left;
    }

    &.table-list{

    	th{
    		font-weight: 400;
    		color: #999999;
    		padding-bottom: 1.3rem;
    		white-space: nowrap;
    	}

    	thead,tbody,tr,{
    		color: $dark-gray;
    		font-size: 1.4rem;
    	}

    	td{
    		overflow-wrap: break-word;
    		word-wrap: break-word;
    		border-top: $table-border;
    		background-color: #fff;
           
    	}
        
        tr{
            &:last-child{
                td{
                    border-bottom: $table-border;
                }
            }
            &:hover{
                td{
                    background-color: #fcfcfc;
                }
            }
        }


    	tr td:first-child, tr th:first-child{
    		padding-left: 2rem;
    	}

    	tr td:last-child, tr th:last-child{
    		padding-right: 2rem;
    	}
		
		// top left radius
    	tbody tr:first-child td:first-child{
    		border-top-left-radius: $table-border-radius;
    	}

    	// top right radius
    	tbody tr:first-child td:last-child{
    		border-top-right-radius: $table-border-radius;
    	}

    	// bottom right radius
    	tbody tr:last-child td:last-child{
    		border-bottom-right-radius: $table-border-radius;
    	}

    	// bottom left radius
    	tbody tr:last-child td:first-child{
    		border-bottom-left-radius: $table-border-radius;
    	}
		
		// border left
    	tbody tr td:first-child{
    		border-left: $table-border;
    	}

    	// border right
    	tbody tr td:last-child{
    		border-right: $table-border;
    	}
    }
}