.badge{
	display: inline-block;
    text-align: center;
    font-size: 1.4rem;
    line-height: 14px;
    white-space: nowrap;
    border-radius: 30px;
    background: #eeeeee;
    padding: 6px 16px;
    color: #333;
}

