//Top Bar
.topbar-action{
	position: fixed;
	top: 0;
	left: $sidebar-width;
    width: calc(100vw - 240px);
	background: #F5F5F7;
	z-index: $top-bar-index;
	line-height: 69px;
	height: $topbar-height;
	text-align: right;
	padding-right: 10px;
	border-bottom: 1px solid #E8E8E8;
	
	// &.title{
	// 	display: flex;
	// 	justify-content: space-between;
	// 	align-items: center;
	// 	z-index: $top-bar-focus-index;
	// 	min-height: $topbar-height;
	// 	@include breakpoint($sidebar-show-breakpoint){
	// 		min-height: $topbar-mobile-height;
	// 	}

	// 	h2{
	// 	    text-overflow: ellipsis;
	// 	    white-space: nowrap;
	// 	    overflow: hidden;
	// 	    margin-bottom: 0;
	// 	    margin-right: 20px;
	// 		padding-left: 20px;
	// 		display: inline-block;
	// 		text-align: left;

	// 		-webkit-box-flex: 1;
	// 	    -ms-flex: 1 1 0px;
	// 	    flex: 1 1 0px;
	// 	    width: auto;
	// 	}
	// 	span{
	// 		-webkit-box-flex: 0;
	// 	    -ms-flex: 0 0 auto;
	// 	    flex: 0 0 auto;
	// 	    width: auto;
	// 	}
	// }
	&,
	&.focus{
		z-index: $top-bar-focus-index;
	}

	@include res(md-and-down, $--breakpoints-spec){
		height: $topbar-mobile-height;
		line-height: 57px;
	}

	@include res(lg){
		padding-right: 25px;
	}
	@include res(md-and-down, $--breakpoints-spec){
		left: 0;
	    width: 100%;
	}
}
