$form-group-margin: 3rem;
$input-height: 38px;
// $textarea-height: 85px;
// $input-label-margin: 0.5rem;
$input-radius: 3px;
$input-hover-color: $medium-gray;
// $search-input-height: 36px;

.input-label{
    font-size: $--font-size-base;
    color: #333;
    display: inline-block;
    margin-bottom: .8rem;
}

.input-wrapper{
	margin-bottom: $form-group-margin;
    &.input-wrapper--small{
        margin-bottom: $form-group-margin/2 !important;
    }
}

.input-control{
	font-weight: $global-weight-normal;
	min-height: $input-height;
	border: 1px solid #ddd;
	border-radius: $input-radius;
	width: 100%;
	transition: all .15s ease-in-out;
	box-shadow: inset 1px 2px 0px 0px rgba(0, 0, 0, 0.02);

	background-color: #fff;
	padding: 8px 10px;

	appearance: none;
    font-size: $font-size-base;
    line-height: 1.4;
    height: auto;
	
    &:hover,
    &:focus{
    	border-color: $input-hover-color;
    	box-shadow: inset 0px 0px 2px 0px rgba(0, 0, 0, 0), 0px 2px 2px 0px rgba(0, 0, 0, 0.03);
    	z-index: 3;
    }
    &:focus{
    	border-color: #aaa ;
    }

    &.error{
        &,&:hover{
            border-color: $danger-color;
        }
    }

	&+.input-control{
		margin-top: 1rem;
	}
}

// checkbox and radio
input[type="radio"],
input[type="checkbox"]{
    cursor: pointer;
    opacity: 0;
    position: absolute;
    height: 30px;
    width: 30px;
    margin: -2px 0 0 -5px;
    z-index: 3;

    &+.input-checkmark{

        position: absolute;
        margin-top: 2px;
        display: inline-block;
        border: 1px solid #cccccc;
        border-radius: 2px;
        background: #fff;
        box-shadow: 0px 1px 2px rgba(0,0,0,0.05);
        vertical-align: middle;
        cursor: pointer;
        transition: all .12s ease-in-out;

        &+label{
            cursor: pointer;
            padding: 0px 3px 0px 25px;
            margin-right: 1rem;
            display: inline-block;
        }
    }

    &:checked + .input-checkmark{
        border: 2px solid #418ff0;
        background-color: #418ff0;
    }
}

input[type="checkbox"]{
    & +.input-checkmark{
        height: 16px;
        width: 16px;
    }
    &:checked + .input-checkmark{
        background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20viewBox%3D%220%200%2024%2024%22%3E%3Ctitle%3Echeck%3C/title%3E%3Cpath%20d%3D%22M9.6%2C20.26a1.5%2C1.5%2C0%2C0%2C1-1-.39L1.16%2C13.11a1.5%2C1.5%2C0%2C0%2C1%2C2-2.22L9.5%2C16.64%2C20.71%2C4.24a1.5%2C1.5%2C0%2C0%2C1%2C2.22%2C2L10.71%2C19.76a1.5%2C1.5%2C0%2C0%2C1-1%2C.49Z%22%20style%3D%22fill%3A%23fff%22/%3E%3C/svg%3E");
    }
}

input[type="radio"]{
    & +.input-checkmark{
        border-radius: 50%;
        width: 17px;
        height: 17px;
    }
    &:checked + .input-checkmark{
        background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20viewBox%3D%220%200%2024%2024%22%3E%3Ctitle%3Eradio%3C/title%3E%3Ccircle%20cx%3D%2212%22%20cy%3D%2212%22%20r%3D%227%22%20style%3D%22fill%3A%23fff%22/%3E%3C/svg%3E");
    }
}

.error-block{
    display: block;
    line-height: 1.2rem;
    font-size: 1.2rem;
    margin-top: 0.3rem;
    color: $danger-color;
}

.help-block{
    display: block;
    margin-top: 0.3rem;
    font-size: 1.4rem;
    line-height: 1.5;
    color: lighten($dark-gray, 20%);
    font-style: normal;
}

// input with attached button or addon
.input-group{
    position: relative;
    display: flex;
    // border-collapse: separate;
    width: 100%;

    .input-group-addon{
    	// for span use
        padding: 0px 12px;
        font-size: $font-size-base;
        line-height: 1;
        color: #474747;
        text-align: center;
        background-color: #fff;
        border: 1px solid #ddd;
        border-radius: $input-radius;
        width: 13%;
        min-width: 60px;
        white-space: nowrap;
        vertical-align: middle;
        display: table-cell;
        position: relative;
        z-index: 3;
        // &.background-color{
        //     background-color: $light-gray;
        // }
        // &.small{
        //     font-size: 1.3rem;
        //     padding: 5px 10px;
        // }
    }
	
	.input-group-addon,
	.input-control,
	.btn{
		// display: table-cell;
		box-shadow: none;
		&:first-child{
			border-bottom-right-radius: 0;
			border-top-right-radius: 0;
		}

		&:last-child{
			border-bottom-left-radius: 0;
			border-top-left-radius: 0;
		}
	}
 

	.input-control{
		&:first-child{
			border-right: 0;
		}

		&:last-child{
			
		}
	}

}

// input with link inside input control
.input-control-group{
    position: relative;
    .input-control{
        padding-right: 6rem;
    }
    .btn-link{
        position: absolute;
        right: 0;
        top: 0;
        padding: 0 1.5rem;
        .svg-icon{
            fill: #333;
        }
        &:hover,&:focus{
            .svg-icon{
                fill: lighten(#333,20%);
            }
        }
    }
}

// form group, 
.form-group{
    @include grid($gutter: 1rem, $gutter-x: true, $cell-name: input-wrapper);

    

    &.form-group--spaced{
        justify-content: space-between;
    }
    &.form-group--even{
        .input-wrapper{
            flex-grow: 1;
        }
    }
    &.form-group--2{
        .input-wrapper{
            @include grid-cell($gutter: 1rem, $gutter-x: true);
            margin-bottom: 3rem;
        }
        @include res(sm){
            .input-wrapper{
                @include grid-cell(1/2, $gutter: 1rem, $gutter-x: true);
            }
        }
    }
}


// upload-wrapper
.upload{
    &-wrapper{
        align-items: center;
    }
    &-description{
        flex: 1 0 0;
        margin: 0.8rem 1.6rem;
        min-width: 15rem;
    }
}