// New Animation
.slide-fade-enter-active {
  transition: all .1s ease;
}
.slide-fade-leave-active {
  transition: all .1s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateY(-8px) scale(.9);
  opacity: 0;
}

//popover component
.popover-actions{
	&.el-popover.el-popper{
		padding: .5rem 0;
		min-width: 13rem;
	}
	ul{
		list-style-type: none;
		li{
			&.separator{
				border-top: 1px solid #E8E8E8;
				margin: .7rem 0;
				padding: 0 !important;
			}
		}
		// &.choice-list{
		// 	li{
		// 		padding: 0;
		// 		&+li{
		// 			margin-top: .3rem;
		// 		}
		// 		.title-link{
		// 			margin-bottom: 0;
		// 		}
		// 	}
		// }
	}
}

ul.action-list{
	min-width: 13rem;
	max-width: 28rem;
    max-height: 380px;
    overflow: auto;

    
	li{
		a,button{
			display: block;
			width: 100%;
			text-align: left;
			font-size: $font-size-base;
		    color: #666666;
			padding: .7rem 2rem;
			white-space: nowrap;
			background: #fff;

			&:hover:not(.disabled),
			&:focus:not(.disabled){
				background: #4184f0;
				color: #fff;
				
				.button-content,
				.button-content .button-title{
					color: #fff;
				}
				svg.svg-icon{
					fill: #fff;
				}
			}
			&.icon-button,
			&.icon-button-label{
				.svg-icon{
					fill: #666
				}
				margin-left: 0;
				.fa{
					font-size: 1.6rem;
				    padding: 0.6rem;
				    width: 30px;
				    height: 30px;
				    margin-right: 0;
				    text-align: center;
				    vertical-align: baseline;
				}
			}
			&.icon-button.small{
				.svg-icon{
					width: 25px;
					height: 25px;
					padding: 0;
					margin-right: 10px;
				}
			}
		}
	}
}