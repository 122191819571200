/* 
Note: 
show sidebar breakpoint : md-and-down, $--breakpoints-spec 
*/

.nav{
									
	&-topbar{
		height: $topbar-height;
		border-bottom: 1px solid $page-border-color;

		display: flex;
		align-items: stretch;

		width: 100%;
		background: $topbar-background;

		@include res(md-and-down, $--breakpoints-spec){
			height: $topbar-mobile-height;
		}

		&__logo{
			height: 100%;
			border-right: 1px solid $page-border-color;
			box-sizing: border-box;
			display: flex;
			justify-content: center;
			align-items: center;

			width: $sidebar-width;
			min-width: $sidebar-width;
			@include res(md-and-down, $--breakpoints-spec){
				display: none;
			}

			img{
				display: block;
				width: 185px;
				height: auto;
			}

		}
		&__list{
			
			display: flex;
			justify-content: flex-end;
		    flex: 1 1 auto;
		}
		&__item{
			
			a{
				text-decoration: none;
			}
			@include res(md-and-down, $--breakpoints-spec){
				display: none;
			}
			// &.dropdown{
			// 	>a{
			// 		&:hover{
			// 			background: rgba(0,0,0,0.03);
			// 		}
			// 		&.active{
			// 			background: rgba(0,0,0,0.05);
			// 		}
			// 	}
			// 	ul{
			// 		left: initial;
			// 		right: 1.5vw;
			// 		width: min-content;
			// 		&:before{
			// 			left: 50%;
			// 			transform: translate(-50%,0),rotate(45deg);
		 // 				-webkit-transform: translate(-50%,0),rotate(45deg);
			// 		}
			// 	}
			// }
		}
	}

	&-sidebar{

		height: 100%;
		box-sizing: border-box;
		background: $topbar-background;
		overflow-y: auto;
		padding-left: 5px;

		&::-webkit-scrollbar-track
		{
			border-radius: 10px;
			background-color: rgba(0,0,0,0);
		}

		&::-webkit-scrollbar
		{
			width: 5px;
			background-color: rgba(0,0,0,0.0);
		}
		&::-webkit-scrollbar-thumb
		{
			border-radius: 10px;
			background-color: rgba(0,0,0,0);
			transition: all .2s ease-in-out;
			// @include transition(all .2s ease-in-out);

			@include res(md-and-down, $--breakpoints-spec){
				background-color: rgba(0,0,0,0.15);
			}
		}

		&:hover::-webkit-scrollbar-thumb
		{
			background-color: rgba(0,0,0,0.15);
		}

		&__group{
			list-style: none;
			min-width: 0;
			margin: 0;
	    	margin-top: 2.2rem;
			

			// Add New
			// margin: 4rem 0rem 0rem 3rem;
			&:last-child{
		    margin-bottom: 2.2rem;
			}
			&:nth-last-child(2){
				@include res(lg){
					// @include flex-grow(1);
				}
			}

			@include res(md-and-down, $--breakpoints-spec){
				margin-top: 1.5rem;
				&+ .nav-sidebar__group{
					margin-top: 3rem;
				}
			}
		}

		

		&__link{
			color: lighten($dark-gray, 15%);
			// @include display_flex;
			// @include align-items(center);
			// @include flex-grow(1);
			display: flex;
			align-items: center;
			padding: 0 $nav-link-padding;
	    	height: 4rem;
			min-width: 0;
			cursor: pointer;
			user-select: none;

			// @extend .flex-container;
			// @include flex-align(null,middle);
			img {
				width: 36px;
				height: 36px;
			}


			@include res(md-and-down, $--breakpoints-spec){
				height: 4rem;
			}

			&:hover,&:focus{
				color: $black;
				.sidebar-icon{
					fill: $black;
				}
			}
			&.selected, &.active{ 
				font-weight: bold; 
				color: $black;
				.sidebar-icon{
					fill: $black;
				}
			}

			.sidebar{
				&-icon{
					fill: lighten($dark-gray, 15%);
					// @include flex-shrink(0);
					flex-shrink: 0;
					display: inline-block;
					vertical-align: middle;
					width: 22px;
					height: 22px;
					padding: 0px 0.8rem;
					box-sizing: content-box;
				}
				
				&-label{
					padding: 0px 0.8rem;
					// @include flex-grow(1);

				}

				&-badge{
					font-weight: 400;
					padding: 0 0.6rem;
					margin: 0 0.8rem;
					border-radius: 0.8rem;
					background-color: lighten( #bf2626, 15%);
					color: #ffffff;
					font-size: 1.2rem;
					line-height: 1.6rem;
					min-width: 20px;
					text-align: center;
				}

			}



		}
	}

	&-scroll{

		&_wrapper{

		}

		&_container{
			
		}
	}
}

/*
Topbar profile
*/
.check-icon {
	border-radius: 50%;
	width: 16px;
	height: 16px;
	background-size: 16px;
	background-repeat: no-repeat;
	background-position: center;
	background-image: url(https://s3-ap-southeast-1.amazonaws.com/easystore.website/icons/svg/check-mark.svg);
}
.topbar{
	position: fixed;
	top: 0;
	left: $sidebar-width;
    width: calc(100vw - 240px);
	background: #F5F5F7;
	z-index: $top-bar-index;
	line-height: 69px;
	height: $topbar-height;
	text-align: right;
	padding-right: 10px;
	border-bottom: 1px solid #E8E8E8;
	
	&.title{
		display: flex;
		justify-content: space-between;
		align-items: center;
		z-index: $top-bar-focus-index;
		min-height: $topbar-height;
		@include res(md-and-down, $--breakpoints-spec){
			min-height: $topbar-mobile-height;
		}

		h2{
		    text-overflow: ellipsis;
		    white-space: nowrap;
		    overflow: hidden;
		    margin-bottom: 0;
		    margin-right: 20px;
			padding-left: 20px;
			display: inline-block;
			text-align: left;

			-webkit-box-flex: 1;
		    -ms-flex: 1 1 0px;
		    flex: 1 1 0px;
		    width: auto;
		}
		span{
			-webkit-box-flex: 0;
		    -ms-flex: 0 0 auto;
		    flex: 0 0 auto;
		    width: auto;
		}
	}
	&,
	&.focus{
		z-index: $top-bar-focus-index;
	}

	@include res(md-and-down, $--breakpoints-spec){
		height: $topbar-mobile-height;
		line-height: 57px;
	}

	@include res(lg){
		padding-right: 25px;
	}
	@include res(md-and-down, $--breakpoints-spec){
		left: 0;
	    width: 100%;
	}


	&-profile{
		cursor: pointer;
		display: flex;
		justify-content: center;
		padding: 0 25px;
		min-height: $topbar-height;
		height: 100%;
		max-width: 300px;
		align-items: center;
		@include res(md-and-down, $--breakpoints-spec){
			min-height: $topbar-mobile-height;
		}

		&__avatar{
			position: relative;
			.avatar{
				
				display: block;
				width: 36px;
				height: 36px;
				margin-right: 5px;
				img{
					width: 100%;
					height: 100%;
					overflow: hidden;
					border-radius: 50%;
				}
			}
			.check-icon{
				position: absolute;
				right: 0;
				bottom: 0;
			}
		}
		&__detail{
			// font-family: $header-font-family;
			width: 100%;
			min-width: 70px;
			max-width: 200px;
			padding: 5px 10px;
			.user-name{
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				color: #666666;
				margin: 0;
				line-height: 20px;
				font-size: 1.3rem;
			}
			.store-name{
				line-height: 20px;
				color: #000000;
				font-weight: 400;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				margin: 0;
			}
		}
		.svg-icon{
			width: 24px;
			height: 20px;
			padding: 1px;
		}
	}
}
