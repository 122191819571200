.site-home{
	.home-layout{
		// display: flex;
		// flex-wrap: wrap;
		// .card{
		// 	flex: 1 1 50%;
		// 	width: 50%
		// }
	}
	.page-header__title{
		text-align: center;
	}
	.home-title{
		font-weight: 600;
	}
	.home-title-2{
		color: lighten($dark-gray, 40%);
	}
	.home-card{
		text-align: center;
		height: 100%;
	}
	.home-icon{
		max-width: 100px;
		margin-left: auto;
		margin-right: auto;
	}
}