/*
Custom grid structure
*/

// $grid-gutter: 2rem;

// .grid{
// 	display: flex;
// 	flex-flow: row wrap;

// 	&.grid-margin{
// 		margin-left: $grid-gutter/2*-1;
// 		margin-right: $grid-gutter/2*-1;
// 		.cell{
// 			margin-left: $grid-gutter/2;
// 			margin-right: $grid-gutter/2;
// 		}
// 		&.grid--desktop-2{
// 			.cell{
// 				width: calc(50% - #{$grid-gutter});
// 			}
// 		}
// 	}


// 	// grid cell size
// 	.cell{
// 		flex: 0 0 auto;
// 		min-height: 0px;
// 		min-width: 0px;
// 		width: 100%;
// 	}
// 	&.grid--desktop-2{
// 		.cell{
// 		    width: 50%;
// 		}
// 	}

// }