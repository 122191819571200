// text alignment
@mixin text-alignment {
  @each $align in (left, right, center, justify) {
    .text-#{$align} {
      text-align: $align;
    }
  }
}


// calculate rem
@function rem-calc($values) {
  $remValues: #{$values / 10}rem;
  @return $remValues; 
}

// for header size
@each $size, $headers in $header-styles {
  @include res($size) {
    @each $header, $header-defs in $headers {
      $font-size-temp: 1rem;
      #{$header}, .#{$header} {
          
       $font-size-temp: rem-calc(map-get($header-defs, font-size));
       font-size: $font-size-temp;
       margin-bottom: 1.5rem;
      }
    }
  }
}



/* Custom grid mixins */

// grid and grid-cell margin
@mixin grid-gutter(
  $gutter: null,
  $gutter-x: false,
  $gutter-y: false,
  ){
  @if ($gutter-x == true){
    margin-right: $gutter/2*-1;
    margin-left: $gutter/2*-1;
  }

  @if ($gutter-y == true){
    margin-top: $gutter/2*-1;
    margin-bottom: $gutter/2*-1;
  }
}

@mixin grid-cell-gutter(
  $gutter: null,
  $gutter-x: false,
  $gutter-y: false,
  ){
  @if ($gutter-x == true){
    margin-right: $gutter/2;
    margin-left: $gutter/2;

  }

  @if ($gutter-y == true){
    margin-top: $gutter/2;
    margin-bottom: $gutter/2;
  }
}


// grid and grid-cell structure
@mixin grid(
  $gutter: null,
  $gutter-x: false,
  $gutter-y: false,
  $cell-name: cell,
  ){
  display: flex;
  flex-flow: row wrap;
  @if ($gutter != null){
    @include grid-gutter($gutter, $gutter-x, $gutter-y);
    
    .#{$cell-name}{
      @include grid-cell-gutter($gutter, $gutter-x, $gutter-y);
    }

  }
}

@mixin grid-cell(
  $size: 1,
  $gutter: null,
  $gutter-x: false,
  $gutter-y: false,
  ){
  flex: 0 0 auto;
  min-height: 0px;
  min-width: 0px;
  $cell-percentage: $size*100%;

  @if ($gutter == null){
    width: $cell-percentage;
  }
  @else{
    @if ($gutter-x == true){
      width: calc(#{$cell-percentage} - #{$gutter});
    }
    @else{
      width: $cell-percentage;
    }

    @include grid-cell-gutter($gutter, $gutter-x, $gutter-y);
  }
}