/* 
Note: 
show sidebar breakpoint : md-and-down, $--breakpoints-spec 
*/
											
.page-frame{
	min-height: 100%;
	width: 100%;

	// == Avoid jumping srcoll bar ==
	// margin-left: calc(100vw - 100%);
	margin-left: 0;
    margin-right: 0;
    // == ** ==

	&__header{
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		width: 100vw;
		height: $topbar-height;
		z-index: $page-header-index;
		box-sizing: border-box;

		display: flex;
		align-items: middle;


		@include res(md-and-down, $--breakpoints-spec){
			height: $topbar-mobile-height;
		}

	}

	&__aside{
		background-color: $sidebar-background;
		position: fixed;
		left: 0;
		top: $topbar-height;
		height: calc(100% - 70px);
		box-sizing: border-box;
		border-right: 1px solid $page-border-color;
		width: $sidebar-width;

		z-index: $page-aside-index;

		.mobile-nav{
			display: none;
			position: fixed;
			top: 14px;
			left: 260px;
			padding: 2px;
			cursor: pointer;
			
			@include res(md-and-down, $--breakpoints-spec){
				display: block;
			}

			span{
			  display: block;
			  width: 33px;
			  height: 4px;
			  margin-bottom: 6px;
			  position: relative;
			  
			  background: rgb(165,165,165);
			  border-radius: 3px;
			  
			  z-index: 1;
			  
			  transform-origin: 4px 0px;
			  transition: all 0.35s cubic-bezier(0.77,0.2,0.05,1.0);
			  // @include transition(all 0.35s cubic-bezier(0.77,0.2,0.05,1.0));
			}

			span:first-child{
			  transform-origin: 0% 0%;
			}

			span:nth-last-child(2){
			  transform-origin: 0% 100%;
			}

			

		}

		
		@include res(md-and-down, $--breakpoints-spec){
			top: 0;
		    left: -$sidebar-width;
		    height: 100%;
			z-index: $page-aside-mobile-index;
		    transform: translate(0px, 0px);
		    transition: transform 0.3s cubic-bezier(0, 1, 0.5, 1);

			.nav-sidebar{
				position: relative;
				padding-top: 71px;
			}
		    &.active{
		    	transform: translate($sidebar-width, 0);
				box-shadow: 8px 0px 10px rgba(0,0,0,0.2);

		    	.sidebar-avatar{
		    		transform: translate($sidebar-width, 0);
		    	}

		    	.mobile-nav{
		    		background-color: rgba(0,0,0,0.55);
				    width: 100vw;
				    height: 100vh;
				    overflow: hidden;
				    top: 0;
				    left: $sidebar-width;
				    .burger{
				    	position: fixed;
				    	top: 14px;
						left: 260px;

				    	span{
							margin-left: 8px;
							opacity: 1;
							transform: rotate(45deg) translate(-2px, -2px);
							background: rgb(240,240,240);
						}
						span:nth-child(3){
							opacity: 1;
							transform: rotate(-45deg) translate(-2px, -5px);
						}
						span:nth-child(2){
							opacity: 0;
							transform: rotate(0deg) scale(0.2, 0.2);
						}
				    }
					
			    }
		    }
		  
		}


		.nav-sidebar .sidebar-avatar{
			display: none;
			position: absolute;;
			top: 0;
			right: $sidebar-width;
			width: $sidebar-width;
			height: 70px;
			border-bottom: 1px solid #E8E8E8;

			.account-menu{
				display: block;
			}

			.topbar-profile{
				max-width: 240px;
				padding: 8px 12px;

				.topbar-profile__detail{
					max-width: 138px;
				}
			}

			@include res(md-and-down, $--breakpoints-spec){
				display: block;
			}
		}
	}

	&__main{
		// display: block;
		padding-top: $topbar-height;
		padding-left: $sidebar-width;

		@include res(md-and-down, $--breakpoints-spec){
			padding-top: $topbar-mobile-height;
		}

		@include res(md-and-down, $--breakpoints-spec){
			padding-left: 0;
		}
	}

	&--full{
		// padding-top: 0;
		@include res(xs){
			padding-top: 0;
		}
		padding-left: 0;
	}
	// &__public{
	// 	padding-top: 1em;
	// 	padding-bottom: 1em;
	// 	@include breakpoint(large){
	// 		padding-top: 4em;
	// 		padding-bottom: 4em;
	// 	}
	// }
}


@mixin page-structure{
	max-width: $page-max-width;
	margin-right: auto;
    margin-left: auto;
    box-sizing: border-box;
    margin-top: 2rem;
    margin-bottom: 2rem;
    padding-right: 0;
    padding-left: 0;
	
	@include res(sm){
    	padding-right: $page-padding-sm;
    	padding-left: $page-padding-sm;
    }

    @include res(md){
    	padding-right: $page-padding-md;
    	padding-left: $page-padding-md;
    }

    @include res(lg){
    	padding-right: $page-padding-lg;
    	padding-left: $page-padding-lg;
    }
}

// for page main structure 
.page{

	&-header-wrapper{
		@include page-structure;
		margin-bottom: 0;
		@include res(xs-only,$--breakpoints-spec){
			padding-right: $page-padding-sm;
			padding-left: $page-padding-sm;
		}
	}

	&-header{
		display: flex;
		min-height: 9rem;
		align-items: center;
		flex-wrap: wrap;
		&__main{
			flex: 1 1 auto;
		}
		&__action{
			display: flex;
			align-items: center;
			flex-flow: row wrap;
			.action-link{
				a{
					color: lighten($dark-gray, 20%);
					margin-right: 2rem;
				    font-size: 1.6rem;
				    display: inline-block;
				    .svg-icon{
				    	vertical-align: middle;
				    	&+span{
				    		vertical-align: middle;
				    	}
				    }
				    .svg-icon{
						width: 24px;
				    	height: 24px;
				    	margin-right: 6px;
				    	fill: lighten($dark-gray, 20%);
				    }
					&:hover,&:focus{
						color: $--color-primary;
						.svg-icon{
							fill: $--color-primary;
						}
					}
				}
			}
		}


		&__title{
			
		}
	}
	
	&-nav-wrapper{
		@include page-structure;
		@include res(xs-only,$--breakpoints-spec){
			padding-right: $page-padding-sm;
			padding-left: $page-padding-sm;
		}
		margin-top: 0;
		border-top: solid 1px $page-border-color;
		border-bottom: solid 1px $page-border-color;
	}

	&-nav{
		display: flex;
		flex-wrap: nowrap;
		align-items: stretch;
		list-style: none;
		&__item{
			display: flex;
		}

		&__link{
			padding: 1.5rem 0;
			display: flex;
			margin-right: 1.5rem;
			justify-content: center;
			align-items: center;
			cursor: pointer;
			text-align: center;
			color: lighten($dark-gray, 40%);
			border-bottom: solid 3px transparent;
			
			&:hover,&:focus{
				color: lighten($dark-gray, 15%);
				border-color: rgba($partner-primary, 0.6);
			}

			&.active{
				color: lighten($dark-gray, 15%);
				border-color: $partner-primary;
			}

		}
	}
	
	&-layout{
		@include page-structure;
	}


	&-empty{
		@include page-structure;
		@include res(xs-only,$--breakpoints-spec){
			padding-right: $page-padding-sm;
			padding-left: $page-padding-sm;
		}
		text-align: center;
		font-size: 1.9rem;
		max-width: 60rem;
		&__header{
			font-weight: normal;
		}
		.svg-icon{
			width: 10rem;
			height: 10rem;
			fill: #cacaca;
			margin-bottom: 1rem;
		}
	}



}


.layout{
	@include grid($gutter: $layout-gutter, $gutter-y: true, $cell-name: layout__section);

	&+.layout{
		margin-top: $layout-gutter/2;
	}
}
.layout__section{
	@include grid-cell($gutter: $layout-gutter, $gutter-y: true);
}

// layout-1 - home use, grid half
.layout--1{

	@include res(md){
		@include grid-gutter($gutter: $layout-gutter, $gutter-x: true, $gutter-y: true);

		.layout__section{
			@include grid-cell(1/2, $gutter: $layout-gutter, $gutter-x: true, $gutter-y: true);
		}
	}
}

// layout-2 - setting use, grid of three
.layout--2{
	
	@include res(md){
		@include grid-gutter($gutter: $layout-gutter, $gutter-x: true, $gutter-y: true);

		.layout__section:nth-child(1n){
			@include grid-cell(1/3, $gutter: $layout-gutter, $gutter-x: true, $gutter-y: true);
		}

		.layout__section:nth-child(2n){
			@include grid-cell(2/3, $gutter: $layout-gutter, $gutter-x: true, $gutter-y: true);
		}
	}


	padding-bottom: 2rem;
	&+.layout--2{
		padding-top: 2rem;
		border-top: 1px solid $page-border-color;
	}
}
