$card-margin: 2rem;

.card{
	background: $card-background;
	position: relative;
	box-shadow: $card-shadow;
	border-radius: 0px;
	
	@include res(sm){
		border-radius: $card-radius;
	}
	
	// h1, h2, h3, h4, h5, h6 {
	// 	margin-bottom: 1rem;
	// }

	&+.card{
		margin-top: $card-margin;
	}

	&--transparent{
		background: transparent;
		box-shadow: none;
		.card__section{
			@include res(sm){
				padding-left: 0;
				padding-right: 0;
			}
		}
	}

	&__header{
	    padding: $card-padding-xs $card-padding-xs 0;
	    @include res(sm){
	    	padding: $card-padding-sm $card-padding-sm 0;
	    }

	    h1, h2, h3, h4, h5, h6 {
	    	margin-bottom: 0;
	    	font-weight: bold;
	    }
	}

	&__section{
	    padding: $card-padding-xs;
	    @include res(sm){
	    	padding: $card-padding-sm;
	    }

	    h1, h2, h3, h4, h5, h6 {
	    	font-weight: bold;
	    }
	}

}