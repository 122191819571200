.site-auth{
	background-image: url(/images/partners/background.svg);
    background-size: cover;
    background-position: center bottom;
    background-repeat: no-repeat;
    height: 100vh;
	.page-layout{
		max-width: 580px;
	}
	&__title{
		font-weight: normal;
		// text-align: center;
		margin-bottom: 2rem;
	}
	&__logo{
		max-width: 200px;
		// margin: 0 auto;
	}

	.page-layout{
		@include res(xs){
			margin-top: 0;
		}
	}
}

// #paypal_login{
// 	.LIwPP.PPBlue{
// 		opacity: 0;
// 	}
// }