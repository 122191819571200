html{
  line-height: 1.15;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  box-sizing: border-box;
  font-size: 62.5%;
}

body {
  font-family: "Helvetica Neue",Helvetica,"PingFang SC","Hiragino Sans GB","Microsoft YaHei","å¾®è½¯éé»",Arial,sans-serif;
  // font-family: Circular, "Helvetica Neue",Helvetica,"PingFang SC","Hiragino Sans GB","Microsoft YaHei","å¾®è½¯éé»",Arial,sans-serif;
  font-weight: 400;
  font-size: $--font-size-base;
  color: $--color-black;
  // -webkit-font-smoothing: antialiased;

  margin: 0;
  padding: 0;
  background: $body-background;

  // width: 100vw;
  // overflow-x: hidden;
}

a {
  color: $--color-primary;
  text-decoration: none;
  cursor: pointer;
  &:hover,
  &:focus {
    color: mix($--color-white, $--color-primary, $--button-hover-tint-percent);
  }

  &:active {
    color: mix($--color-black, $--color-primary, $--button-active-shade-percent);
  }
}

h1, h2, h3, h4, h5, h6 {
  color: $--color-text-regular;
  font-weight: 400;
  font-family: "Helvetica Neue",Helvetica,"PingFang SC","Hiragino Sans GB","Microsoft YaHei","å¾®è½¯éé»",Arial,sans-serif;

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

h1, h2 {
  font-family: Circular, "Helvetica Neue",Helvetica,"PingFang SC","Hiragino Sans GB","Microsoft YaHei","å¾®è½¯éé»",Arial,sans-serif;
}

$header-styles: (
  xs: (
    'h1': ('font-size': 25),
    'h2': ('font-size': 23),
    'h3': ('font-size': 19),
    'h4': ('font-size': 17),
    'h5': ('font-size': 15),
    'h6': ('font-size': 15),
  ),
  sm: (
    'h1': ('font-size': 25),
    'h2': ('font-size': 23),
    'h3': ('font-size': 19),
    'h4': ('font-size': 17),
    'h5': ('font-size': 15),
    'h6': ('font-size': 15),
  ),
  md: (
    'h1': ('font-size': 28),
    'h2': ('font-size': 26),
    'h3': ('font-size': 20),
    'h4': ('font-size': 18),
    'h5': ('font-size': 16),
    'h6': ('font-size': 16),
  ),
);


p {
  line-height: 1.8;
  margin-top: 0;

  &:last-child {
    margin-bottom: 0;
  }
}

sup, sub {
  font-size: #{$--font-size-base - 0.1rem};
}

small {
  font-size: #{$--font-size-base - 0.1rem};
}

hr {
  margin-top: 20px;
  margin-bottom: 20px;
  border: 0;
  border-top: 1px solid #eeeeee;
}

ul, ol{
    margin: 0;
    padding: 0;
}
 img{
  display: block;
  max-width: 100%;
 }

// 
*, *:after, *:before{
    outline: none;
    -webkit-overflow-scrolling: touch;
    box-sizing: border-box;
}
// Firefox
button:-moz-focusring, [type="button"]:-moz-focusring, [type="reset"]:-moz-focusring, [type="submit"]:-moz-focusring{
    outline: none;
}
// custom scrollbar
// 
body,*{
  &::-webkit-scrollbar-track
  {
    border-radius: $card-radius;
    background-color: transparent;
  }

  &::-webkit-scrollbar
  {
    width: 7px;
    height: 7px;
    border-right: 10px;
    background-color: transparent;
    border-radius: $card-radius;
  }
  &::-webkit-scrollbar-thumb
  {
    border-radius: $card-radius;
    background-color: rgba(0,0,0,0.25);
  }
}


// form
input,textarea{
  font-family: $body-font-family;
  font-weight: $global-weight-normal;
  &::placeholder {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #aaa;
  }
}



input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  appearance: none;
  margin: 0;
}
label{
  user-select: none;
  font-size: $font-size-base;
  color: $dark-gray;
}
button{
  font-weight: $global-weight-normal;
  cursor: pointer;
}
input[type="number"], 
select{
 user-select: none;
}
input[type="number"]{
  -moz-appearance: textfield;
}
select{
  background-color: transparent;
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20viewBox%3D%220%200%2024%2024%22%3E%3Cpath%20d%3D%22M12%2C.5a.93.93%2C0%2C0%2C1%2C.77.54l4.8%2C7.83a1%2C1%2C0%2C0%2C1%2C.1.82.63.63%2C0%2C0%2C1-.5.5H6.88a.61.61%2C0%2C0%2C1-.54-.49%2C1%2C1%2C0%2C0%2C1%2C.12-.86L11.27%2C1A.91.91%2C0%2C0%2C1%2C12%2C.5Z%22/%3E%3Cpath%20d%3D%22M12%2C23.5a.93.93%2C0%2C0%2C1-.77-.54l-4.8-7.83a1%2C1%2C0%2C0%2C1-.1-.82.63.63%2C0%2C0%2C1%2C.5-.5H17.12a.61.61%2C0%2C0%2C1%2C.54.49%2C1%2C1%2C0%2C0%2C1-.12.86L12.73%2C23A.91.91%2C0%2C0%2C1%2C12%2C23.5Z%22/%3E%3C/svg%3E");
  background-position: right 4px center;
  padding-right: 2rem !important;
  background-size: 11px;
  background-repeat: no-repeat;
  padding: .6rem 1.5rem .6rem 1.2rem;
  margin-left: -1px;
  line-height: 1.4;
  &:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #333;
  }
}
textarea {
  resize: none;
  min-height: 10rem;
  // @extend .input-control;

  // &.input-control{
    //     height: $textarea-height;
    // }
  }
