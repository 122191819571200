/* === Global and Layout === */
/* ========================= */
$partner-primary: #283748;
$danger-color: #C53E3E;
$light-gray: #F5F5F7;
$medium-gray: #cacaca;
$dark-gray: #333333;
$black: #000000;
$white: #ffffff;
$body-background: $light-gray;


// Font and color
$body-font-family: "Helvetica Neue",Helvetica,"PingFang SC","Hiragino Sans GB","Microsoft YaHei","å¾®è½¯éé»",Arial,sans-serif;
// $body-font-family: Circular,"Helvetica Neue",Helvetica,"PingFang SC","Hiragino Sans GB","Microsoft YaHei","å¾®è½¯éé»",Arial,sans-serif;
$body-font-color: #333;
$font-size-base: 1.6rem;
$font-size-label: 1.6rem;
$global-weight-normal: 400;
$global-weight-bold: 600;
$global-lineheight: 1.6;
$body-antialiased: true;


//Page
$page-border-color: #E8E8E8;

// Page structure
// padding will affect card padding
$page-max-width: 1200px;
$page-padding-sm: 1.6rem;
$page-padding-md: 3rem;
$page-padding-lg: 3.5rem;


// Nav topbar and sidebar
$topbar-background: #F5F5F7;
$topbar-height: 70px;
$topbar-mobile-height: 58px;

$sidebar-background: #F5F5F7;
$sidebar-width: 240px;
$nav-link-padding: 12px;


// Top-bar (popover z-index : 2000+)
$page-aside-index: 1000;
$page-header-index: 1100;
$top-bar-index: 1200;
$page-aside-mobile-index: 1201;
$top-bar-focus-index: 1202;


$layout-gutter: 2rem;

/* === Components === */
/* =================== */

// Dropdown
$dropdown-shadow: 0 2px 12px 0 rgba(0,0,0,0.1);


//card
$card-background: #fff;
$card-radius: 6px;
$card-shadow: 0px 15px 45px 0px rgba(0, 0, 0, 0.05);
$card-padding-xs: $page-padding-sm;
$card-padding-sm: 3rem;

//button
$button-radius: 5px;

$primary-button-color: #283748;
$primary-button-hover-color: lighten(#283748, 5%);

$danger-button-color: #C53E3E;
$danger-button-hover-color: lighten(#C53E3E, 5%);

$default-button-color: rgba(255, 255, 255, 0.5);
$default-button-border-color: #cacaca;

// Table List
$table-border: 1px solid $page-border-color;
$table-border-radius: 6px;
