// Edit app form
.app-icon__wrapper{
	width: 60px;
	height: 60px;
	overflow: hidden;
    border-radius: 3px;
}

.app-banner__wrapper{
    border-radius: 3px;
	width: 200px;
	overflow: hidden;
}

.badge{
	&.published{
		background: #abefa7;
	}
	&.unpublished{
		background: #FAEF9B;
	}
	&.rejected{
		color: #999;
	}
	&.pending{
		background: #FAEF9B;
	}
}
// .show-secret-field{
// 	.btn-link{
// 		.svg-icon{
// 			fill: #333;
// 		}
// 		padding: 0 1rem;
// 	}
// }