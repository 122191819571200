//=========== override el-input ===========
.el-input:not(.el-input--mini){
    .el-input__inner{
        @extend .input-control;
    }
}

.el-select .el-input.is-focus,
.el-input.is-focus{
    .el-input__inner{
        border-color: #aaa;
    }
}
// .el-input.el-input--mini{
//     .el-input__inner{
//         @extend .input-border;
//         @extend .input-small;
//     }
// }
.el-date-editor.el-input{
    width: 100%;
    &.el-input--prefix.el-input--suffix{
        .el-input__inner{
            padding: 0 30px;
        }
    }
}
.el-select{
    display: block;
    .el-input__inner{
        padding-left: 1rem;
    }
    .el-select__input{
        margin-left: 10px;
    }
}

// Modal
.el-message-box{
    width: 90%;
    max-width: 450px;
    
    // to align with message box padding in variable
    padding-bottom: 20px;

    &__btns{
        padding: 5px 20px 0;
    }
}