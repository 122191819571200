$input-border: #ddd;
$border-radius-base: 3px;
$input-group-addon-bg: #fff;
$text-color: #666;
$text-color-hover: #418ff0;

.froala{
  > span{
      font-size: 1.5rem;
      color: #666666;
  }
}
// ----- remove p style ----- //
.fr-box{
  p{
    color: initial;
    line-height: 1.4;
  }
}

//----- remove box shadow -----//
.fr-toolbar, .fr-toolbar.fr-top, .fr-toolbar.fr-bottom {
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	border-top: none;
	border: solid 1px $input-border;
}

.fr-toolbar.fr-top{
	border-radius: $border-radius-base $border-radius-base 0 0 !important;
}

.fr-toolbar.fr-bottom{
	border-radius: 0 0 $border-radius-base $border-radius-base !important;
}

.fr-box.fr-basic.fr-top .fr-wrapper, .fr-box.fr-basic.fr-bottom .fr-wrapper{
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	border: solid 1px $input-border;
}

.fr-box.fr-basic.fr-top .fr-wrapper {
	border-radius: 0 0 $border-radius-base $border-radius-base;
	border-top: none;
}

.fr-box.fr-basic.fr-bottom .fr-wrapper {
	border-radius: $border-radius-base $border-radius-base 0 0;
	border-bottom: none;
}

.fr-popup {
  -webkit-box-shadow: 0 5px 10px rgba(0,0,0,0.12);
  -moz-box-shadow: 0 5px 10px rgba(0,0,0,0.12);
  box-shadow: 0 5px 10px rgba(0,0,0,0.12);
  border-radius: $border-radius-base;
  -moz-border-radius: $border-radius-base;
  -webkit-border-radius: $border-radius-base;
  border-top: none !important;
  border: solid 1px $input-border;
  z-index: 1001 !important;
}

.fr-popup.fr-above {
  margin-top: -10px;
  border-top: 0;
  border-bottom: none;
  -webkit-box-shadow: 0 5px 10px rgba(0,0,0,0.12);
  -moz-box-shadow: 0 5px 10px rgba(0,0,0,0.12);
  box-shadow: 0 5px 10px rgba(0,0,0,0.12);
  border: solid 1px $input-border;
}

.fr-popup .fr-buttons {
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
  border-radius: $border-radius-base;
  -moz-border-radius: $border-radius-base;
  -webkit-border-radius: $border-radius-base;

  padding: 0;
}

//----- custom arrow -----//
.fr-popup.fr-desktop .fr-arrow {
  border-bottom: 5px solid $input-border;
  margin-left: -9px;
  
}

.fr-popup.fr-desktop.fr-above .fr-arrow {
  
}

.fr-popup.fr-desktop .fr-arrow {
  width: 0;
  height: 0;
  border-left: none;
  border-right: none;
  border-bottom: none;
  position: absolute;
  top: -12px;
  left: 50%;
  // margin-left: -5px;
  display: inline-block;
  &:before, &:after{
    content: "";
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid  $input-border;
    border-top: none;
    position: absolute;
  }
  &:before{
    top: 4px;
    border-bottom-color: $input-border;
    border-width: 8px;
  }
  &:after{
    top: 6px;
    left: 1px;
    border-bottom-color: #ffffff;
    border-width: 7px;
  }
}

.fr-popup.fr-desktop.fr-above  .fr-arrow {
  top: auto;
  bottom: -9px;
  border-bottom: 0;
  border-top-color: inherit;
  border-top-style: solid;
  border-top-width: 5px;
  &:before, &:after{
    content: "";
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid $input-border;
    border-bottom: none;
    position: absolute;
  }
  &:before{
    top: -9px;
    border-top-color: $input-border;
    border-width: 8px;
  }
  &:after{
    top: -10px;
    left: 1px;
    border-top-color: #ffffff;
    border-width: 7px;
  }
}

//----- remove toolbar seperator padding and margin-----//
.fr-toolbar{
  padding: 0px;
}

.fr-separator.fr-vs {
  height: 35px;
  margin: 0 0;
}
.fr-separator.fr-hs {
  width: 100%;
  margin: 0 0;
}

.fr-toolbar .fr-command.fr-btn,
.fr-popup .fr-command.fr-btn{
  margin: 0 0;
  height: 34px;
}

//----- customized dropdown-----//
.fr-command.fr-btn + .fr-dropdown-menu {
  box-shadow: $card-shadow;
  border-radius: 0 0 $border-radius-base $border-radius-base;
  -moz-border-radius: 0 0 $border-radius-base $border-radius-base;
  -webkit-border-radius: 0 0 $border-radius-base $border-radius-base;

}

.fr-command.fr-btn.fr-active+.fr-dropdown-menu {
  -webkit-box-shadow: $dropdown-shadow;
  -moz-box-shadow: $dropdown-shadow;
  box-shadow: $dropdown-shadow;
  border-radius: 0 0 $border-radius-base $border-radius-base;
  -moz-border-radius: 0 0 $border-radius-base $border-radius-base;
  -webkit-border-radius: 0 0 $border-radius-base $border-radius-base;
  margin-left: -1px;
}

.fr-command.fr-btn.fr-active + .fr-dropdown-menu {
    border: solid 1px $input-border;
}

//----- toolbar icon and seperator padding and margin-----//
.fr-toolbar .fr-command.fr-btn i,
.fr-popup .fr-command.fr-btn i {
  font-size: 13px;
  width: 15px;
  margin: 11px 8.5px;
  text-align: center;
}

//----- toolbar bg color-----//
.fr-toolbar, .fr-toolbar.fr-top, .fr-toolbar.fr-bottom {
  background-color: $input-group-addon-bg;
}

//----- reuse fa class for table option -----//
// .fa-rotate-90 {
//     -webkit-transform: rotate(90deg);
//     -ms-transform: rotate(90deg);
//     transform: rotate(90deg);
// }

//----- color tab shadow fix -----//
.fr-popup .fr-colors-tabs{

}
//----- fr-place holder -----//
.fr-wrapper .fr-placeholder{
  color: $medium-gray;
}
//==== Icon style ====//
//
//  Icon postfix name Documentation

//==== Toolbar icon [31+3]====//
// -bold
// -italic
// -underline
// -strikethrough
//@ -subscript
//@ -superscript
// -font (font-family)
// -text-height (font-size)
// -tint (color)
// -smile-o
// -paint-brush (inline style)
// -magic (paragraph style)
// -paragraph (paragraph format)
// -align-left (1+3)
// -list-ol
// -list-ul
// -outdent
// -indent
// -minus
// -link
// -image
// -video-camera
// -table
// -rotate-left
// -rotate-right
// -eraser (clear formating)
// -mouse-pointer (select all)
// -code
// -file-o (upload)
// -quote-left (quote)
// -expand 

//==== Image option [6]====//
// -exchange (replace image)
// -trash (delete)
// -arrows-alt (resize)
// -star (display)
// -info (img alt)
// -search (search link)
 
//==== Image import option [2]====//
// -upload (image)
// -folder (browse)

//==== Link option [3]====//
// -external-link (goto link)
// -edit (edit link)
// -unlink 

//==== Table option [6-1]====//
// -header
// -bars (row)
// -bars rotate-90 (col)
// -square-o (cell)
// -arrows-v (vertical align)
// -arrow-left (color option back)

.fr-toolbar{
  i{
    display: inline-block;
  }
}

.fr-toolbar .fr-command.fr-btn,
.fr-popup .fr-command.fr-btn {
  color: $text-color !important;
}

.fr-toolbar .fr-command.fr-btn.fr-active, 
.fr-popup .fr-command.fr-btn.fr-active{
  color: $text-color-hover !important;
}

.fr-view table td.fr-borderless,
.fr-view table th.fr-borderless {
    border-style: none
}

