.svg-icon{
	width: 20px;
    height: 20px;
    padding: 2px;
    fill: #333;
   	vertical-align: middle;
    transition: transform 0.15s ease-in-out;
   	

	&.rotate-90-acw{
    	transform: rotate(-90deg);
	}
	&.rotate-90-cw{
    	transform: rotate(90deg);
	}
	&.rotate-180{
    	transform: rotate(180deg);
	}
	// &.fail,
	// &.success{
 //        fill: #fff;
 //        padding: 4px;
 //        border: 1px solid #fff;
 //        border-radius: 50%;
 //        margin-bottom: 3px;
	// }
	// &.success{
 //      background: #14B17A;
 //  }
	// &.fail{
 //      background: $red;
 //  }
 //  &.next-icon{
 //    margin-top: -2px;
 //    margin-right: 7px;
 //    padding: 0;
 //  }
 //  &.color-primary{
 //    fill: $primary-button-color !important;
	// }
}

.svg-icon{
	width: 20px;
	height: 20px;
	fill: #333;
	vertical-align: middle;
	&+span{
		vertical-align: middle;
	}
}

a{
	.svg-icon{
		fill: $--link-color;
		&:hover,&:focus{
			fill: $--link-hover-color;
		}
	}
}